import { Box, Container } from "@mui/system";
import { useEffect, useRef, useState } from "react";

import TotalAllocationComponent from "./components/TotalAllocationComponent";
import FooterComponent from "./components/FooterComponent";
import LinksCardComponent from "./components/LinksCardComponent";
import LoadingComponent from "../components/LoadingComponent";
import { LoadingStatus } from "../contexts/LoadingStatus";
import Me from "../services/Users/Me";
import NewsComponent from "./components/NewsComponent";
import { Notify } from "../components/notify";
import { OrderAgainComponent } from "./components/OrderAgainComponent";
import { OrdersComponent } from "./components/OrdersComponent";
import ReimbursementsComponent from "./components/ReimbursementsComponent";
import { ThemeProvider } from "@emotion/react";
import UserServiceDashboard from "../services/Users/UserDashboardProfile";
import WalletsComponent from "./components/WalletsComponent";
import theme from "../theme/main-theme";
import { useSessionContext } from "../contexts/SessionContext";
import { useTranslation } from "react-i18next";
import userService from "../services/Users/UserService";

export default function UserDashboard() {
  const [state, setState] = useState<UserServiceDashboard | undefined>(undefined);
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.None);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const hasShownModal = useRef(false);

  const { user } = useSessionContext();

  const isAddressEmpty = (user: Me) => {
    const address = user?.user.addresses?.[0];
    return !address?.address || !address?.city || !address?.postalCode;
  };

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    async function getUserDashboard() {
      try {
        setLoading(LoadingStatus.Loading);
        const result = await userService.getUserDashboard();
        if (result && user) {
          setState(result);
          setLoading(LoadingStatus.Success);

          if (!hasShownModal.current && isAddressEmpty(user)) {
            setShowModal(true);
            hasShownModal.current = true;
          }
        }
      } catch {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("dashboard.error-loading"));
      }
    }
    getUserDashboard();
  }, [t, user, hasShownModal]);

  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  if (state === undefined) return <></>;

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box padding={{ xs: "0 4px", sm: "0 20px", md: "0 40px" }}>
          <TotalAllocationComponent state={state} />
          {/*<AddressModal open={showModal} onClose={handleClose} title={t("dashboard.adressModal.title")} content={t("dashboard.adressModal.content")} /> */}
          <NewsComponent state={state} />
          <WalletsComponent />
          <LinksCardComponent />
          <OrdersComponent state={state} />
          <OrderAgainComponent state={state} />
          <ReimbursementsComponent state={state} />
        </Box>
      </Container>
      <FooterComponent />
    </ThemeProvider>
  );
}
