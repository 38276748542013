import { Box, Button, Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import cashIcon from "../assets/images/cash.svg";
import { useSessionContext } from "../contexts/SessionContext";
import OrganizationFeature from "../services/Organizations/OrganizationFeature";
import InfoIcon from "./InfoIcon";
import FormatHelpers from "../services/FormatHelpers";

type Link = {
  label: string;
  url: string;
};

type Props = {
  withoutAllocation: boolean;
  allocation: number;
  balance: number;
  allocationTips?: Record<string, string>;

  link?: Link;
};

function ActionLink({ label, url }: Link) {
  return (
    <Button href={url} target="_parent" fullWidth sx={{ mt: "15px" }}>
      {label}
    </Button>
  );
}

export default function AllocationComponent(props: Props) {
  const { t } = useTranslation();
  const lang = localStorage.getItem("language") || "en";
  const { user, organizationHasFeature } = useSessionContext();

  let allocationTips = undefined;
  if (props.allocationTips) allocationTips = lang === "fr" ? props.allocationTips.fr : props.allocationTips.en;
  allocationTips = allocationTips ? allocationTips.trim() : undefined;

  const useWallet = user?.user.organizationId && organizationHasFeature(user?.user.organizationId, OrganizationFeature.UseAccountWallet);

  const showAllocation = useWallet && props.allocation > 0;

  return (
    <Grid item lg={4} md={5} sm={12} xs={12} sx={{ maxWidth: { lg: "375px", md: "370px", xs: "100%" } }}>
      <Paper elevation={0} sx={{ padding: { xs: "11px 24px 17px 18px", sm: "24px 22px 28px 20px" } }}>
        {!props.withoutAllocation && (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  lineHeight: 1,
                  letterSpacing: "-0.96px",
                  color: "#02334A",
                  whiteSpace: "nowrap",
                }}
              >
                {t("dashboard.userPage.amount-available")}
              </Typography>

              {allocationTips && (
                <Tooltip arrow title={<Box sx={{ padding: "15px", fontSize: "14px" }}>{parse(allocationTips)}</Box>}>
                  <div>
                    <InfoIcon />
                  </div>
                </Tooltip>
              )}

              {showAllocation && (
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: 1,
                    letterSpacing: "-0.96px",
                    color: "#999999",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"/ "}
                  {FormatHelpers.formatMoneyLocale(props.allocation, lang)}
                </Typography>
              )}
            </Box>

            <Stack direction="row" alignItems="middle" mt="10px">
              <img src={cashIcon} alt="Cash icon" style={{ marginTop: "5px" }} />
              <Box sx={{ display: "flex", marginLeft: "20px" }}>
                <Typography
                  sx={{
                    fontSize: "32px",
                    fontWeight: "500",
                    color: "#02334a",
                    letterSpacing: "-0.5px",
                    minWidth: "90px",
                    marginRight: "20px",
                  }}
                >
                  {FormatHelpers.formatMoneyLocale(props.balance, lang)}
                </Typography>
              </Box>
            </Stack>
          </>
        )}

        {props.link && <ActionLink {...props.link} />}
      </Paper>
    </Grid>
  );
}
