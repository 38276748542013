import { Box, Typography } from "@mui/material";

import OrganizationFeature from "../../services/Organizations/OrganizationFeature";
import Wallet from "./Wallet";
import { useSessionContext } from "../../contexts/SessionContext";
import { useTranslation } from "react-i18next";

export default function WalletsComponent() {
  const { t } = useTranslation();
  const { user, organizationHasFeature } = useSessionContext();

  const userOrgId = user?.user.organizationId;
  const userOrg = user?.organizations.find((o) => o.id === userOrgId);

  const useWallet = userOrgId && organizationHasFeature(userOrgId, OrganizationFeature.UseAccountWallet);
  if (!userOrg || !useWallet) return <></>;

  const filteredOrgWallets = userOrg?.wallets.filter((w) => user.user.wallets.findIndex((uw) => uw.organizationWalletId === w.id) >= 0);

  if (filteredOrgWallets.length === 0) {
    return <></>;
  }

  return (
    <Box mb={8} mt={-8}>
      <Typography variant="h3" mb={1.75}>
        {t("dashboard.userPage.wallets-label")}
      </Typography>

      {filteredOrgWallets.map((wallet) => {
        return <Wallet key={wallet.id} {...wallet} />;
      })}
    </Box>
  );
}
