import { useParams } from "react-router-dom";
import userService from "../../services/Users/UserService";
import { ThemeProvider } from "@emotion/react";
import theme from "../../theme/main-theme";
import { Box, Container, Stack } from "@mui/system";
import { CircularProgress, Grid, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import WalletLink from "../../services/Organizations/WalletLink";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import { useSessionContext } from "../../contexts/SessionContext";
import { Notify } from "../../components/notify";
import i18n from "../../services/i18n";
import AllocationComponent from "../../components/AllocationComponent";
import ServicesList from "../../userWallet/components/ServicesList";
import Category from "../../services/Marketplace/Category";
import marketplaceService from "../../services/Marketplace/MarketplaceService";
import OrganizationHelpers from "../../services/Organizations/OrganizationHelpers";

export default function WalletPage() {
  const { t } = useTranslation();
  const { walletId } = useParams<string>();
  const { user } = useSessionContext();

  const reimbursementUrl = `${userService.appPortalUrl}/cascade-refunds?orgWalletID=${walletId}`;
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [links, setLinks] = useState<WalletLink[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const orgWallet = user && walletId ? OrganizationHelpers.getOrganizationWallet(user.organizations, user.user.organizationId, walletId) : undefined;
  const wallet = user?.user.wallets.find((wallet) => wallet.organizationWalletId === walletId);
  const walletTitle = user?.organizations.flatMap(({ wallets }) => wallets).find(({ id }) => id === walletId)?.display[i18n.language];

  const getStatus = (category: Category): any => {
    if (category.orgStatus === "soon") return t("walletPage.bientot");
    if (category.orgStatus === "new" && category.containsNewProduct) return t("walletPage.nouveaute");
    if (category.orgStatus === "new") return t("walletPage.nouveau");
    return "";
  };

  useEffect(() => {
    async function get() {
      try {
        if (user && walletId) {
          setLoading(LoadingStatus.Loading);

          const links = await userService.getWalletLinks(walletId);
          const categories = await marketplaceService.getWalletCategories(
            user.user.organizationId,
            walletId,
            user.user.addresses[0] && user.user.addresses[0].postalCode
          );

          setLinks(links);
          setCategories(categories);
          setLoading(LoadingStatus.Success);
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("walletPage.error-loading"));
      }
    }

    get();
  }, [t, user, walletId]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box pt={{ xs: "40px", sm: "40px", md: "80px" }} px={{ xs: "20px", sm: "20px", md: "40px" }}>
          <Grid container alignItems="center" justifyContent="space-between" pb="50px" gap="25px">
            <Box>
              <Typography variant="h2">{t("walletPage.header")}</Typography>
              <Typography variant="h1">{walletTitle}</Typography>
            </Box>
            <AllocationComponent
              withoutAllocation={orgWallet?.withoutAllocation ?? false}
              allocation={wallet?.allocation || 0}
              balance={wallet?.balance || 0}
              link={{ url: reimbursementUrl, label: t("walletPage.refund") }}
            />
          </Grid>
          <Typography variant="h3" sx={{ mt: "20px", mb: "32px" }}>
            {t("walletPage.services")}
          </Typography>
          {loading === LoadingStatus.Loading ? (
            <Stack justifyContent="center" direction="row">
              <CircularProgress />
            </Stack>
          ) : (
            <>
              <ServicesList links={links} />
              {Array.from({ length: Math.ceil(categories.length / 2) }, (_, index) => index).map((_, index) => (
                <Grid container direction="column" spacing={1.5}>
                  <Grid key={categories[index * 2].key} container item alignItems="center">
                    <Grid
                      item
                      xs={6}
                      style={{
                        backgroundColor: "white",
                        color: "gray",
                        padding: "20px",
                        textAlign: "center",
                        border: "1px solid gray",
                        borderRadius: "5px",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                        height: "200px",
                      }}
                    >
                      <Link
                        key={categories[index * 2].key}
                        target={"_parent"}
                        component={RouterLink}
                        to={`${process.env.REACT_APP_PORTAL_URL}/${categories[index * 2].slug[i18n.language]}`}
                        underline="none"
                      >
                        {categories[index * 2].name[i18n.language]}
                      </Link>
                      <Typography>{categories[index * 2].description[i18n.language]}</Typography>
                      <Grid container spacing={1.5} xs={12}>
                        <Grid item xs={4}>
                          {categories[index * 2].icon && categories[index * 2].icon !== "" && (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={`${process.env.REACT_APP_STORAGE_URL}/category/${categories[index * 2].icon}`}
                              alt=""
                            />
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {categories[index * 2].image && categories[index * 2].image !== "" && (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={`${process.env.REACT_APP_STORAGE_URL}/category/${categories[index * 2].image}`}
                              alt=""
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>{getStatus(categories[index * 2])}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {categories[index * 2 + 1] && (
                      <Grid
                        key={categories[index].key}
                        item
                        xs={6}
                        style={{
                          backgroundColor: "white",
                          color: "gray",
                          padding: "20px",
                          textAlign: "center",
                          border: "1px solid gray",
                          borderRadius: "5px",
                          boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                          height: "200px",
                        }}
                      >
                        <Link
                          key={categories[index * 2 + 1].key}
                          target={"_parent"}
                          component={RouterLink}
                          to={`${process.env.REACT_APP_PORTAL_URL}/${categories[index * 2 + 1].slug[i18n.language]}`}
                          underline="none"
                        >
                          {categories[index + 1].name[i18n.language]}
                        </Link>
                        <Typography>{categories[index * 2 + 1].description[i18n.language]}</Typography>
                        <Grid container spacing={1.5} xs={12}>
                          <Grid item xs={4}>
                            {categories[index * 2 + 1].icon && categories[index * 2 + 1].icon !== "" && (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={`${process.env.REACT_APP_STORAGE_URL}/category/${categories[index * 2 + 1].icon}`}
                                alt=""
                              />
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            {categories[index * 2 + 1].image && categories[index * 2 + 1].image !== "" && (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={`${process.env.REACT_APP_STORAGE_URL}/category/${categories[index * 2 + 1].image}`}
                                alt=""
                              />
                            )}
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>{getStatus(categories[index * 2 + 1])}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
