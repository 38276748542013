import { Box, Stack, Typography } from "@mui/material";
import WalletLink from "../../services/Organizations/WalletLink";
import { NavigationType } from "../../services/Organizations/NavigationType";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import i18n from "../../services/i18n";
import arrowIcon from "../../assets/images/arrow.svg";

export default function ServiceLink(props: WalletLink) {
  const target = props.navigationType === NavigationType.External ? "_blank" : props.navigationType === NavigationType.Parent ? "_parent" : "";
  return (
    <Link key={props.navigationUrl[i18n.language]} target={target} component={RouterLink} to={props.navigationUrl[i18n.language]} underline="none">
      <Stack
        sx={{
          height: "354px",
          borderRadius: "30px",
          background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 59.53%, rgba(0, 0, 0, 0.60) 100%)",
          backgroundColor: "white",
          backgroundImage: `url('${props.imageUrl[i18n.language]}')`,
        }}
        justifyContent="flex-end"
      >
        <Stack direction="row" alignItems="flex-end" px="25px" pb="33px" justifyContent="space-between">
          <Typography variant="h5">{props.display[i18n.language]}</Typography>
          <Box
            sx={{
              width: "64px",
              height: "64px",
              borderRadius: "50%",
              background: "#26A68F",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={arrowIcon} alt="Arrow Icon" height="22px" width="22px" />
          </Box>
        </Stack>
      </Stack>
    </Link>
  );
}
